import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import useTheme from '@mui/material/styles/useTheme';

// import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";
// import HomeTitle from "./HomeTitle";
// import WavedFrameAnimationForChildrenLazyPreload from "../../_shared/WavedFrameAnimationForChildrenLazyPreload";
import { POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_STAR } from "../../../utils/clipPathsConstants";
// import SeparatorAbsSvg2Lazy from "../../_shared/shaping/SeparatorAbsSvg2Lazy";
// import FrameMaskForDiv from "../../_shared/FrameMaskForDiv";
import IntroTextNacoWebStrankyCCh from "./IntroTextNacoWebStrankyCCh";

const LazyPreloadWavedFrameAnimationForChildren = lazy(() => import(/* webpackMode: "lazy", preload: true, webpackChunkName: 'LazyPreloadWavedFrameAnimationForChildren' */ '../../_shared/WavedFrameAnimationForChildren'))
const LazyPreloadAnimateScrollIntoViewport = lazy(() => import(/* webpackMode: "lazy", preload: true, webpackChunkName: 'LazyPreloadAnimateScrollIntoViewport' */ '../../_shared/AnimateScrollIntoViewport'))
const LazyPreloadSeparatorAbsSvg2 = lazy(() => import(/* webpackMode: "lazy", preload: true, webpackChunkName: 'LazyPreloadSeparatorAbsSvg2' */ '../../_shared/shaping/SeparatorAbsSvg2Lazy'))
const LazyPreloadFrameMaskForDiv = lazy(() => import(/* webpackMode: "lazy", preload: true, webpackChunkName: 'LazyPreloadFrameMaskForDiv' */ '../../_shared/FrameMaskForDiv'))

const LazyPreloadHomeTitle = lazy(() => import(/* webpackMode: "lazy", preload: true, webpackChunkName: 'LazyPreloadHomeTitle' */ './HomeTitle'));

const FullUp = (props) => {

    const { title, breakpoint } = props
    const theme = useTheme()

    const stylesBottom = [ { strokeWidth: '13', stroke: theme.palette.warning.main }, { strokeWidth: '15', stroke: theme.palette.info.main } ]
    const sepFillColor = theme.palette.primary2.dark
    const sepFillColorContrastText = theme.palette.primary2.contrastText
    return (<>
        <Container
            maxWidth={false}
            className="cont contUp"
            key="conttitle"
            sx={{
                position: "relative",
                pt: 0, // [2, 4, 6, 8],
                pb: 0,
                px: [ 0.5, 1, 2, 3 ],
                transformStyle: "preserve-3d",
                mb: "3rem",
            }}
        >
            <div
                className="wrapTitleBoost"
                style={{
                    width: "100%",
                    perspective: "1000px",
                }}
            >
                <Suspense fallback={<div>Loading AnimateScrollIntoViewport Title ...</div>}>
                    <LazyPreloadAnimateScrollIntoViewport
                        name={`homeTitle-${breakpoint}`}
                        key={`homeTitle-${breakpoint}`}
                    >
                        <LazyPreloadHomeTitle title={title} breakpoint={breakpoint} />
                    </LazyPreloadAnimateScrollIntoViewport>
                </Suspense>
            </div>
        </Container>
        <Container
            maxWidth="lg" //{false}
            className="cont cont3D"
            key="cont3D"
        >

            <Paper
                className="paperSubt clay"
                elevation={24}
            // sx={borderRadius: '50%',padding: ['25%','50%']}

            >
                <Suspense fallback={<div>Loading WavedFrameAnimationForChildren ...</div>}>
                    <LazyPreloadWavedFrameAnimationForChildren
                        // {...optionsWavedFrameAnimationForChildren}
                        // optionsFrames={{
                        //     top:{
                        //         createPaddings: true,
                        //     }
                        // }}
                        name="wavedSpravneInview"
                        nameWavedFrames="baseT2R2B2L2_circ" //</Paper> "baseB2"
                        namePaddings="T10-R20-B10-L20"
                        // stylesBottom= {[ {fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ]}
                        // stylesBottom= {[ {fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ]}
                        stylesBottom={[ ...stylesBottom ]}
                        key="wavedFr Tvorba-web-stranok"
                    >

                        <h2
                            id="Tvorba-web-stranok"
                            className="subTitle toc toc0"
                        >
                            <span>Tvorba web stránok</span>
                        </h2>
                    </LazyPreloadWavedFrameAnimationForChildren>
                </Suspense>
                {/* <hr /> */}
                <Suspense fallback={<div>Loading WavedFrameAnimationForChildren ...</div>}>
                    <LazyPreloadWavedFrameAnimationForChildren
                        // {...optionsWavedFrameAnimationForChildren}
                        // optionsFrames={{
                        //     top:{
                        //         createPaddings: true,
                        //     }}
                        // }
                        nameWavedFrames="baseT2R2B2L2_cushion"
                        // namePaddings="T10-R10-B10-L10"
                        namePaddings="T10-R20-B10-L20"
                        // stylesTop= {[ {strokeWidth: '13', stroke: theme.palette.text, fill:theme.palette.primary.dark }, { strokeWidth: '5',stroke: theme.palette.info.main } ]}
                        key="wavedFr - Excel"
                    >
                        <h2
                            id="Tvorba-aplikacii-pre-excel"
                            className="subTitle toc toc0 clay"
                        >

                            <span>Tvorba aplikácií pre Excel</span>
                        </h2>
                    </LazyPreloadWavedFrameAnimationForChildren>
                </Suspense>
            </Paper>
            <Suspense fallback={<div>Loading AnimateScrollIntoViewport ...</div>}>
                <LazyPreloadAnimateScrollIntoViewport
                    durationIn={0.5}
                    durationOut={0.2}
                    animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
                    exitFramer={{ clipPath: POLYGON_STAR, }}
                    styleToMerge={{ clipPath: POLYGON_STAR, overflow: "inherit" }}
                    name="intro"
                    key="intro"
                >

                    <LazyPreloadSeparatorAbsSvg2
                        name="sepNormalRel2FullHorizontalHills"
                        id="IntroTextNacoWebStrankyCCh"
                        key="IntroTextNacoWebStrankyCCh"
                        optionsSep={{
                            // dPathOrderNotIn: [ 0 ],
                            styleWrapSep: {
                                lineHeight: "inherit",
                                // height: "auto" 
                            },
                            styleSvg: { height: "50px", transform: 'rotateY(180deg)' },
                            svgYPadding: 0,
                            isRelative: true,
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                backgroundColor: sepFillColor,
                                color: sepFillColorContrastText,
                            }}
                        >
                            <Container maxWidth="xl" className='cont'>
                                <LazyPreloadFrameMaskForDiv options={{
                                    frames: [ "right", "bottom", "left" ],
                                    masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
                                    rightFr: { isCornerMask: true }, bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
                                }} >
                                    <IntroTextNacoWebStrankyCCh />
                                </LazyPreloadFrameMaskForDiv>
                            </Container>
                        </div>
                    </LazyPreloadSeparatorAbsSvg2>
                </LazyPreloadAnimateScrollIntoViewport>
            </Suspense>
        </Container>
    </>
    );
};

FullUp.propTypes = {
    children: PropTypes.node,
};

export default FullUp;
